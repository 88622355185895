import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import moment from 'moment';

import { APIUtiltity } from '../../API';
import { errorMsg, successMsg } from '../../config';
import utils from '../../utils';

// import { ReactComponent as EditIcon } from '../../img/svg/Actions/Edit.svg';
import { ReactComponent as BackIcon } from '../../img/svg/Arrows/BackArrow.svg';
import { ReactComponent as S3Wallet } from '../../img/svg/Logos/s3-wallet.svg';
import { ReactComponent as SSWalletLogo } from '../../img/svg/Logos/ss-s3-logo.svg';

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
const defaultEmailErrorMsg = 'Entered email address is invalid';
const defaultOtpArray = ['', '', '', '', '', ''];

function EmailVerification({ setStep = () => {}, setUserData = () => {} }) {
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState('');
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [accessCodeErrorMsg, setAccessCodeErrorMsg] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpArray, setOtpArray] = useState(defaultOtpArray);
  const otp = otpArray.join('');
  const [otpErrorMsg, setOtpErrorMsg] = useState('');
  const [resendOtpTime, setResendOtpTime] = useState(0);
  const codeInputRefs = React.useRef([]);

  React.useEffect(() => {
    let id = null;
    if (resendOtpTime > 0) {
      id = setTimeout(() => setResendOtpTime((time) => time - 1), 1000);
    } else if (id) clearTimeout(id);
    return () => {
      if (id) clearTimeout(id);
    };
  }, [resendOtpTime]);

  const emailPasteHandler = (ev = {}) => {
    if (otpSent || submitting) return;
    ev.preventDefault();
    const clip = ev.clipboardData.getData('text');
    const email = clip.replace(/\s/g, '');
    setEmail(email);
    setEmailErrorMsg('');
  };

  const codeInputHandler = (key = '', index = 0, clear = false) => {
    if (submitting) return;
    let value = '';
    const start = otpArray.slice(0, index);
    const end = index === 6 ? [] : otpArray.slice(index + 1);
    if (clear) {
      if (key === 'Enter') return emailVerificationHandler();
      else if (key !== 'Delete' && key !== 'Backspace') return;
    } else if (!key.trim() || !/[0-9]/.test(key)) return;
    else value = key;
    setOtpErrorMsg('');
    const inputFocusIndex = value ? index + 1 : index - 1;
    if (codeInputRefs.current[inputFocusIndex]) {
      codeInputRefs.current[inputFocusIndex].focus();
      codeInputRefs.current[inputFocusIndex].select();
    }
    setOtpArray([...start, value, ...end]);
  };

  const codePasteHandler = (ev = {}) => {
    if (submitting) return;
    setOtpErrorMsg('');
    ev.preventDefault();
    const clip = ev.clipboardData.getData('text');
    const code = clip.replace(/\s/g, '');
    if (!/^[0-9]{6}$/.test(code)) return errorMsg('Invalid code pasted!');
    setOtpArray([...code]);
    codeInputRefs.current[5].focus();
  };

  const emailVerificationHandler = async (resendOtp = false) => {
    if (!emailRegex.test(email) || email.split('@').length > 2) {
      return setEmailErrorMsg(defaultEmailErrorMsg);
    } else if (!accessCode) {
      return setAccessCodeErrorMsg('Please enter Access Code');
    }
    setSubmitting(true);
    if (!otpSent || resendOtp) {
      setOtpErrorMsg('');
      const res = await APIUtiltity.generateEmailOtp(
        email,
        'LOGIN_OTP',
        accessCode
      );
      if (res.status === 200) {
        // setOtpArray(defaultOtpArray);
        successMsg('Code sent to email successfully');
        setOtpSent(true);
        if (res.data?.token) setOtpArray(`${res.data.token}`.split(''));
        setTimeout(() => {
          if (codeInputRefs.current[0]) codeInputRefs.current[0].focus();
        }, 1);
        setResendOtpTime(45);
      } else if (res.status === 403)
        errorMsg('Kindly Provide Correct Access Code Or connect with support!');
      else errorMsg(res.message);
    } else {
      if (otp.length !== 6) return setSubmitting(false);
      const res = await APIUtiltity.verifyEmailOtp(
        email,
        otp,
        'LOGIN_OTP',
        accessCode
      );
      if (res.status === 400) {
        errorMsg(res.message);
        setOtpErrorMsg(res.message);
      } else if (res.status === 200) {
        if (!res.data || !res.data?.token) return errorMsg(res.message);
        const obj = { email, accessKey: res.data.token, timestamp: moment() };
        localStorage.setItem('session_data', utils.getEncrypted(obj));
        successMsg('Code verified successfully');
        if (res.data?.walletId) {
          setUserData(res.data);
          setStep(3, res.data?.userType);
        } else setStep(1);
      } else errorMsg(res.message);
    }
    setSubmitting(false);
  };

  return (
    <div className="email-verification-modal">
      <div className="modal-title mb-4">
        {!otpSent ? (
          <>
            <SSWalletLogo className="mb-4" />
            <div className="mb-2">
              Welcome to <strong className="text-blue"> StarStake!</strong>{' '}
            </div>
            Let’s begin by <span>connecting</span> your email address.
          </>
        ) : (
          <>
            <div className="mb-4">
              <span
                className="py-2 cursor-pointer"
                onClick={() => setOtpSent(false)}
              >
                <BackIcon />
              </span>
            </div>
            Enter the OTP sent to your Email address
            <p>
              Sent to {email}.{' '}
              <span
                onClick={() => {
                  setOtpSent(false);
                  setEmail('');
                }}
              >
                Change?
              </span>
            </p>
          </>
        )}
      </div>
      <Form className="modal-form mb-2" autoComplete="off">
        {!otpSent ? (
          <>
            <Form.Group className="mb-4 position-relative">
              <Form.Control
                id="connect-email"
                autoFocus
                autoComplete="off"
                placeholder="Enter your email address"
                className="modal-form-input"
                type="email"
                value={email}
                onChange={(e) => {
                  if (otpSent || submitting) return;
                  setEmail(e.target.value);
                  setEmailErrorMsg('');
                }}
                onBlur={() =>
                  setEmailErrorMsg(
                    email
                      ? emailRegex.test(email)
                        ? ''
                        : defaultEmailErrorMsg
                      : 'Please enter email address'
                  )
                }
                onPaste={emailPasteHandler}
                style={{
                  borderColor: emailErrorMsg !== '' ? '#ff3f56' : '',
                  paddingRight: 40
                }}
                onKeyDown={(event = {}) => {
                  if (event.key === 'Enter' && !otpSent) {
                    event.preventDefault();
                    if (!submitting) emailVerificationHandler();
                  }
                }}
              />
              <Form.Text className="text-pink">{emailErrorMsg}</Form.Text>
              <Form.Text className="input-info-text">
                *Your email is required for one time password authorization
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Control
                id="connect-code"
                placeholder="Enter Access Code"
                className="modal-form-input"
                type="number"
                value={accessCode}
                onChange={(e) => {
                  if (submitting) return;
                  setAccessCode(e.target.value);
                  setAccessCodeErrorMsg('');
                }}
                onBlur={() =>
                  setAccessCodeErrorMsg(
                    !accessCode ? 'Please enter access code' : ''
                  )
                }
                style={{
                  borderColor: accessCodeErrorMsg !== '' ? '#ff3f56' : ''
                }}
                onKeyDown={(event = {}) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    if (!submitting) emailVerificationHandler();
                  } else if (!event.ctrlKey) utils.numberInputValidation(event);
                }}
              />
              <Form.Text className="text-pink">{accessCodeErrorMsg}</Form.Text>
            </Form.Group>
          </>
        ) : (
          <Form.Group className="mb-4 position-relative">
            <div className="otp-input-container mb-3">
              {otpArray.map((code = '', index = 0) => (
                <input
                  key={index}
                  type="number"
                  name={'otp' + (index + 1)}
                  maxLength="1"
                  autoComplete="off"
                  className="otp-input"
                  tabIndex={index + 1}
                  value={code}
                  onKeyUp={({ key = '' }) => codeInputHandler(key, index, true)}
                  onChange={({ target: { value = '' } }) =>
                    codeInputHandler(value.slice(-1), index)
                  }
                  onPaste={codePasteHandler}
                  ref={(ref) => (codeInputRefs.current[index] = ref)}
                />
              ))}
            </div>
            <Form.Text className="d-flex justify-content-between">
              <span className="text-pink">{otpErrorMsg} &nbsp;</span>
              <span
                onClick={() => {
                  if (submitting) return;
                  if (resendOtpTime === 0) emailVerificationHandler(true);
                }}
                className={`text-white-50 ${
                  resendOtpTime ? '' : 'text-blue cursor-pointer'
                }`}
              >
                Resend Code{' '}
                {resendOtpTime > 0 ? 'in ' + resendOtpTime + ' seconds' : ''}
              </span>
            </Form.Text>
          </Form.Group>
        )}
        <Button
          disabled={!email || (!otp && otpSent) || submitting}
          className="modal-submit-button mt-2"
          onClick={() => emailVerificationHandler()}
        >
          {otpSent ? 'Verify Code' : 'Continue'}
        </Button>
        {!otpSent && (
          <div className="s3-wallet-branding">
            Secured by <S3Wallet /> S<sup>3</sup> Wallet
          </div>
        )}
      </Form>
    </div>
  );
}

export default EmailVerification;
