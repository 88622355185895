import React from 'react';

class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null };
  static getDerivedStateFromError(error) {
    console.log(error);
    return { hasError: true, error };
  }
  render() {
    if (this.state.hasError) {
      if (sessionStorage.getItem('quick-refreshed')) return this.props.children;
      sessionStorage.setItem('quick-refreshed', 'true');
      window.location.reload();
      return this.props.children;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
