import React from 'react';
import { Button } from 'react-bootstrap';

import './index.scss';
import { SANDBOX_ACCESS_REDIRECT_URL } from '../../config';

import { ReactComponent as MainLogo } from '../../img/svg/Header/starstake-beta-logo.svg';
import { ReactComponent as SandboxLogo } from './starstake-sandbox-logo.svg';
import { ReactComponent as ArrowIcon } from './right-arrow-icon.svg';

function SandboxPage({ onEnterSandbox = () => {} }) {
  return (
    <div className="sandbox-page-wrapper">
      <div className="header-section">
        <div className="content-container">
          <div className="logo-container">
            <MainLogo />
          </div>
          <div className="text-container">
            Welcome to the private StarStake Creator Sandbox{' '}
            <span>[Access Code Required]</span>
          </div>
          <div className="btn-container ms-auto">
            <Button onClick={onEnterSandbox}>Enter Sandbox</Button>
          </div>
        </div>
      </div>
      <div className="main-section">
        <div className="first-section">
          <div className="logo-container">
            <SandboxLogo />
          </div>
          <div className="text-container">
            <h1>
              A private Playground for Building your brand club economy on
              starstake
            </h1>
            <p>
              <span className="text-decoration-underline">
                StarStake Sandbox
              </span>{' '}
              is a private application for creators & brands to enjoy the
              flexibility to create & test at their convenience, without the
              audience.
            </p>
          </div>
          <div className="btn-container">
            <Button onClick={onEnterSandbox}>Enter Sandbox</Button>
          </div>
          <div className="bottom-section">
            <div className="text-section">
              <h5>Need Access?</h5>
              <span>
                Learn how to become a creator <br />
                on StarStake today!
              </span>
            </div>
            <div
              className="icon-section"
              onClick={() => window.open(SANDBOX_ACCESS_REDIRECT_URL, '_blank')}
            >
              <ArrowIcon />
            </div>
          </div>
        </div>
        <div className="second-section">
          <div className="second-section-img" />
        </div>
      </div>
    </div>
  );
}

export default SandboxPage;
