import * as crypto from 'crypto-js';
import Compressor from 'compressorjs';
import axios from 'axios';
import $ from 'jquery';
import {
  REACT_APP_IMAGES_PUBLIC_URL,
  URL_ENCODING_KEY,
  PASSWORD_ENCODING_KEY,
  SHORT_CODE_URL,
  IMAGE_SIZE_COMPRESS_LIMIT,
  IMG_IPFS_HOST,
  CHECKLIST_MAPPING,
  CROSS_URL_ENC_KEY,
  SECRET_ENCODING_KEY
} from './config';

export default {
  getShortAccountId(account) {
    let address = '' + (account ? account : '');
    if (!account) {
      return '......';
    }
    return (
      address.slice(0, 8) +
      '.....' +
      address.slice(address.length - 3, address.length)
    );
  },

  getLargeAccountId(account) {
    let address = '' + (account ? account : '');
    if (!account) {
      return '......';
    }
    return (
      address.slice(0, 28) +
      '.....' +
      address.slice(address.length - 3, address.length)
    );
  },

  removeComma(str) {
    return Number(str.toString().replace(/,/g, ''));
  },

  getAmountUpto2Decimals(num = 0) {
    if (!num) return '$' + Number(num || 0);
    return '$' + Number(num).toFixed(2).replace('.00', '');
  },

  getTruncatedString(string = '', maxChars = 28) {
    if (!string || !maxChars) return '';
    if (string.length < maxChars) return string;
    return string.slice(0, maxChars) + '...';
  },

  getEncrypted(obj) {
    let cipherTextId = crypto.AES.encrypt(
      JSON.stringify(obj),
      URL_ENCODING_KEY
    ).toString();
    cipherTextId = this.formatAfterEncrypt(cipherTextId);
    return cipherTextId;
  },

  getEncryptedPassword(obj) {
    let cipherTextId = crypto.AES.encrypt(
      JSON.stringify(obj),
      PASSWORD_ENCODING_KEY
    ).toString();
    cipherTextId = this.formatAfterEncrypt(cipherTextId);
    return cipherTextId;
  },

  formatAfterEncrypt(cipherText) {
    let dataString = cipherText
      .replace(/\+/g, 'p1L2u3S')
      .replace(/\//g, 's1L2a3S4h')
      .replace(/=/g, 'e1Q2u3A4l');
    return dataString;
  },

  getDecrypted(data) {
    let cipherTextId = this.formatBeforeDecrypt(data);
    let bytes = crypto.AES.decrypt(cipherTextId, URL_ENCODING_KEY);
    let decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
    return decryptedData;
  },

  getDecryptedCrossSite(data) {
    let cipherTextId = this.formatBeforeDecrypt(data);
    let bytes = crypto.AES.decrypt(cipherTextId, CROSS_URL_ENC_KEY);
    let decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
    return decryptedData;
  },

  getDecryptedPrivateKey(data) {
    let cipherTextId = this.formatBeforeDecrypt(data);
    let bytes = crypto.AES.decrypt(cipherTextId, SECRET_ENCODING_KEY);
    let decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
    return decryptedData;
  },

  formatBeforeDecrypt(cipherText) {
    // console.log("cipherText::", cipherText)
    let dataString = cipherText
      .replace(/p1L2u3S/g, '+')
      .replace(/s1L2a3S4h/g, '/')
      .replace(/e1Q2u3A4l/g, '=');
    return dataString;
  },

  storeDataIntoCookie(key = '', value = '', expiryInDays = 30) {
    const d = new Date();
    if (value) d.setTime(d.getTime() + expiryInDays * 24 * 60 * 60 * 1000);
    document.cookie = `${key}=${value};expires=${d.toUTCString()};path=/`;
  },

  getDataFromCookie(key = '') {
    const name = key + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      const c = ca[i].trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },

  scrollToTop() {
    $('html, body').animate({ scrollTop: 0 }, 100);
  },

  getMasterDataBySection(section = '', category = 'BANNER') {
    if (!section) return '';
    let masterData = localStorage.getItem('MasterData');
    if (!masterData) return '';
    masterData = JSON.parse(masterData);
    if (Array.isArray(masterData)) {
      const bannerData = masterData.find(
        (data) => data.category === category && data.name === section
      );
      if (bannerData)
        return (
          REACT_APP_IMAGES_PUBLIC_URL.replace('/images-new', '') +
          bannerData.value
        );
      return '';
    }
  },

  getBaseUrl() {
    const url = window.location.href;
    const arr = url.split('/');
    return `${arr[0] + '//' + arr[2]}/#/`;
  },

  getDifferenceWeeks(mintStartDate = '2022-11-13') {
    const msInWeek = 1000 * 60 * 60 * 24 * 7;
    return Math.round(
      Math.abs(new Date() - new Date(mintStartDate)) / msInWeek
    );
  },

  compareWalletAddresses(firsrWalletId = '', secondWalletId = '') {
    if (!firsrWalletId || !secondWalletId) return false;
    return firsrWalletId.toLowerCase() === secondWalletId.toLowerCase();
  },

  numberInputValidation(e) {
    if (e.key.length <= 1 && !/[0-9]/.test(e.key)) e.preventDefault();
  },

  getYoutubeVideoId(youtubeUrl = '') {
    if (!youtubeUrl.includes('v=') && !youtubeUrl.includes('.be')) return '';
    let videoId = '';
    if (youtubeUrl.includes('youtu.be')) {
      videoId = youtubeUrl.split('.be/')[1].split('?')[0];
    } else {
      try {
        const params = new URLSearchParams(new URL(youtubeUrl).search);
        videoId = params.get('v');
      } catch (error) {
        console.log(error);
      }
    }
    return videoId || '';
  },

  getYoutubeVideoThumbnail(videoId = '', setVideoThumbnail = () => {}) {
    if (!videoId) return setVideoThumbnail('');
    const hqThumbnail = `https://img.youtube.com/vi_webp/${videoId}/hqdefault.webp`;
    setVideoThumbnail(hqThumbnail);
    const maxResThumbnail = hqThumbnail.replace('hqdefault', 'maxresdefault');
    const img = new Image();
    img.onload = () => setVideoThumbnail(maxResThumbnail);
    img.onerror = () => {};
    img.src = maxResThumbnail;
    return hqThumbnail;
  },

  getYoutubeEmbedUrl(videoId = '') {
    if (!videoId) return '';
    return `https://www.youtube.com/embed/${videoId}?autoplay=0&cc_load_policy=1&controls=1&disablekb=0&enablejsapi=0&fs=1&iv_load_policy=1&loop=0&rel=0&showinfo=1&start=0&wmode=transparent&theme=dark&mute=0`;
  },

  async getImageFile(src = '') {
    const type = src.match(/[^:/]\w+(?=;|,)/)[0];
    var base64 = src.replace(/^data:image\/(png|jpg|jpeg|webp);base64,/, ''); // |gif
    var sliceSize = 1024;
    var byteChars = window.atob(base64);
    var byteArrays = [];
    for (
      var offset = 0, len = byteChars.length;
      offset < len;
      offset += sliceSize
    ) {
      var slice = byteChars.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: 'image/' + type });
    const fileName = `${Date.now()}.${type}`;
    return new Promise((resolve, reject) => {
      new Compressor(blob, {
        quality: blob.size > IMAGE_SIZE_COMPRESS_LIMIT ? 0.2 : 0.4,
        success: (result) => {
          resolve(new File([result], fileName, { type: blob.type }));
        },
        error: (err) => reject(err)
      });
    });
  },

  forEachPromise(items, fn) {
    return items.reduce(function (promise, item) {
      return promise.then(function () {
        return fn(item);
      });
    }, Promise.resolve());
  },

  createResponseObjectAndSaveInCache(media, type = '') {
    const response = new Response(media, {
      status: 200,
      statusText: 'Ok',
      headers: {
        'content-type': media.type,
        'content-length': media.size,
        'X-file': media.name
      }
    });

    window.caches.open(type + '_cache').then((cache) => {
      const name = response.headers.get('X-file');
      const url = new URL(`/${Date.now()}/${name}`, window.location.origin);
      cache.put(url, response);
      localStorage.setItem(type, url.href);
    });
  },

  async getFileFromSavedInCache(type) {
    const href = localStorage.getItem(type);
    if (!href) return null;
    const cache = await caches.open(type + '_cache');
    if (!cache) return null;
    const response = await cache.match(href);
    if (!response) return null;
    const blob = await response.blob();
    return blob;
  },

  async deleteFileSavedInCache(type) {
    const href = localStorage.getItem(type);
    if (href) caches.delete(type + '_cache');
  },

  getStartsInOrEndsInDuration(startDate, endDate) {
    const utcTodayDate = Date.now();
    const utcStartDate = startDate ? new Date(startDate) : new Date();
    const utcEndDate = endDate ? new Date(endDate) : new Date();
    let utcTargetDate = null;
    let show = '';
    let color = '';
    if (utcTodayDate < utcStartDate) {
      utcTargetDate = utcStartDate;
      show = 'Starts in';
      color = 'green';
    } else if (endDate) {
      if (utcTodayDate < utcEndDate) {
        utcTargetDate = utcEndDate;
        show = 'Ends in';
      } else return { show: 'Ended' };
    } else return null;
    const diffMs = utcTargetDate - utcTodayDate;
    const diffDays = Math.floor(diffMs / 86400000);
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000);
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    if (!color) color = diffDays < 7 ? 'red' : 'purple';
    return { show, color, day: diffDays, hour: diffHrs, minute: diffMins };
  },

  getNumberWithCommas: (number) => {
    return (+number || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },

  getRenamedFile(file) {
    const filePath = file.path || file.name || 'image.png';
    const fileNameArray = filePath.split('.');
    const fileName =
      fileNameArray[0] +
      `-${Date.now()}.` +
      fileNameArray[fileNameArray.length - 1];
    const renamedFile = new File([file], fileName, { type: file.type });
    renamedFile.path = filePath;
    return renamedFile;
  },

  checkValidTitle: (title = '') => {
    return !/^[\w.,|!$&'\s-]*$/.test(title);
  },

  getBuckRarityCount: (bucksArray = []) => {
    let raritycount = [];
    let commonBucks = 0;
    let rareBucks = 0;
    let collectableBucks = 0;
    let ultrarareBucks = 0;
    let legendaryBucks = 0;
    bucksArray?.forEach((buck) => {
      if (buck?.active) {
        if (buck?.rarityClass === 'common')
          commonBucks = commonBucks + buck?.quantity;
        else if (buck?.rarityClass === 'rare')
          rareBucks = rareBucks + buck?.quantity;
        else if (buck?.rarityClass === 'ultrarare')
          ultrarareBucks = ultrarareBucks + buck?.quantity;
        else if (buck?.rarityClass === 'legendary')
          legendaryBucks = legendaryBucks + buck?.quantity;
        else collectableBucks = collectableBucks + buck?.quantity;
      }
    });
    commonBucks > 0 &&
      raritycount.push({
        rarityBuckQuantity: commonBucks,
        buckRarity: 'common'
      });
    collectableBucks > 0 &&
      raritycount.push({
        rarityBuckQuantity: collectableBucks,
        buckRarity: 'collectable'
      });
    rareBucks > 0 &&
      raritycount.push({
        rarityBuckQuantity: rareBucks,
        buckRarity: 'rare'
      });
    ultrarareBucks > 0 &&
      raritycount.push({
        rarityBuckQuantity: ultrarareBucks,
        buckRarity: 'ultrarare'
      });
    legendaryBucks > 0 &&
      raritycount.push({
        rarityBuckQuantity: legendaryBucks,
        buckRarity: 'legendary'
      });

    return [...raritycount];
  },

  makeFirstLetterCapital: (word = '') => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  },

  async fetchShortUrl(url = '', data = {}) {
    const originalUrl = data.originalUrl || `${window.location.origin}/#${url}`;
    try {
      const res = await axios.post(SHORT_CODE_URL, { originalUrl, ...data });
      return res.data?.data?.url || originalUrl;
    } catch (e) {
      console.log(e);
      return originalUrl;
    }
  },

  getImagePathFromURL: (url = '') => {
    let path = '';
    if (url.includes('aws.com/')) path = url.split('aws.com/')[1];
    else if (url.includes('cloudfront')) path = url.split('.net/')[1];
    return path ? IMG_IPFS_HOST + path : url;
  },

  getFanCheckListText: (task = '', condition = 0) => {
    const checklistTask = CHECKLIST_MAPPING[task] || task;
    if (task.includes('Level')) return `${checklistTask} ${condition} or above`;
    if (task.includes('Nft')) return `Receive ${condition} ${checklistTask}s`;
    else if (task.includes('Score')) return `${checklistTask} = ${condition}+`;
    return `Own ${condition} ${checklistTask}`;
  },

  getIframeContent(certificateUrl = '') {
    if (!certificateUrl) return '';
    return `<iframe id="access-nft-certificate" title="Access NFT Certificate" frameborder="0" src="${certificateUrl}" frameborder="0" style="border: none; display: block; min-height: 760px;" name="access-nft-certificate" allow="accelerometer; autoplay; clipboard-write; gyroscope;" width="100%" height="auto"></iframe>`;
  },

  getFormatedNumber: (num) => {
    const decimal18 = 1000000000000000000;
    const getDecimals = (str) => {
      if (str.endsWith('.000000')) {
        return Number(str.replace('.000000', ''));
      } else if (str.endsWith('.00000')) {
        return Number(str.replace('.00000', ''));
      } else if (str.endsWith('.0000')) {
        return Number(str.replace('.0000', ''));
      } else if (str.endsWith('.000')) {
        return Number(str.replace('.000', ''));
      } else if (str.endsWith('.00')) {
        return Number(str.replace('.00', ''));
      } else if (str.endsWith('.0')) {
        return Number(str.replace('.0', ''));
      } else {
        return Number(str);
      }
    };
    if (num === 0) {
      return 0;
    }

    return getDecimals((num / decimal18).toFixed(6));
  }
};
