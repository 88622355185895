import React, { Suspense, lazy, useEffect, useRef } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import MetaMaskOnboarding from '@metamask/onboarding';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import $ from 'jquery';
import 'aos/dist/aos.css';

import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory
} from 'react-router-dom';
import history from './history';
import Web3 from 'web3';
import Fortmatic from 'fortmatic';
import { Magic } from 'magic-sdk';
import moment from 'moment';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import { ReactComponent as LoaderIcon } from './img/svg/Common/Loader.svg';
import './Style/sass/App.scss';
import {
  CHAINID,
  LIST_ADDRESS_BUCKS,
  LIST_ADDRESS_USDC,
  LIST_ADDRESS_TREASURY,
  POLYGONID,
  successMsg,
  errorMsg,
  infoMsg,
  warningMsg,
  INTERCOM_APP_ID as appId,
  POLY_FORTMATIC_NODE,
  FORTMATIC_PRIVATE_KEY,
  ETH_FORTMATIC_NODE,
  MAGICLINK_PRIVATE_KEY,
  ETH_MAGICLINK_NODE,
  POLY_MAGICLINK_NODE,
  isMobileDevice,
  infoIcon
} from './config';
import { APIUtiltity } from './API';
import ContractUtils from './contract/utility';
import MainContext from './contexts/MainContext';
import { MSG } from './msg-const';
import utils from './utils';

import TREASURY_ABI from './contract/abis/Treasury.json';
import ERC20_ABI from './contract/abis/IERC20.json';

import LoaderModal from './Components/Common/Loader/LoaderModal';
import TransactionVerificationModal from './Components/RegistrationModals/TransactionVerification';
import ConnectModal from './Components/RegistrationModals';
import VerifyEmail from './Components/VerifyEmail';
import ErrorBoundary from './ErrorBoundary';
import CreditCardPayment from './Components/CreditCardPayment';
import SandboxPage from './Components/SandboxPage';

const NewHeader = lazy(() => import('./Components/NewHeader'));
const StarStakeGuidelines = lazy(() =>
  import('./Components/StarStakeGuidelines')
);
const ContactUs = lazy(() => import('./Components/ContactUs/ContactUs'));
const ComingSoon = lazy(() => import('./Components/ComingSoon/ComingSoon'));
const FaqGlossary = lazy(() => import('./Components/FaqGlossary/index'));
const LandingPage = lazy(() => import('./Components/LandingPage'));
const AboutUsNew = lazy(() => import('./Components/AboutUsNew'));
const MintGuidelines = lazy(() => import('./Components/MintGuidelines/index'));
// const HowItWorks = lazy(() => import('./Components/HowItWorks/index'));
// const AboutBucks = lazy(() => import('./Components/AboutBucks/index'));
const Error404 = lazy(() => import('./Components/ErrorPages/error404'));
const StakeHub = lazy(() => import('./Components/StakeHub'));
const CreatorHub = lazy(() => import('./Components/CreatorHub'));
const MarketHubWrapper = lazy(() => import('./Components/MarketHub'));
const MissionNFTWrapper = lazy(() => import('./Components/MissionNFT'));
const SnftCheckout = lazy(() => import('./Components/CheckoutFlow'));
const AccessNFTCheckout = lazy(() => import('./Components/AccessNFTCheckout'));
const ProceedToCheckout = lazy(() => import('./Components/ProceedToCheckout'));
const StarProfileDetails = lazy(() =>
  import('./Components/StarProfile/StarProfileDetails')
);
const BuckDetails = lazy(() =>
  import('./Components/Common/BuckDetails/BuckDetails')
);
const SnftDetailsPage = lazy(() => import('./Components/StarStakeNFT/Details'));
const PromotionDetailsPage = lazy(() =>
  import('./Components/StarStakeNFT/StakeHubSNFT/PromotionDetails')
);
const BrandClubHome = lazy(() => import('./Components/BrandClubHome'));
const CollectionDetails = lazy(() =>
  import('./Components/Common/CollectionDetails/CollectionDetails')
);
const BundleDetails = lazy(() =>
  import('./Components/Common/BundleDetails/BundleDetails')
);
const StarConnectDetails = lazy(() =>
  import('./Components/StarConnect/Details')
);
const AccessNFTCollectionDetails = lazy(() =>
  import('./Components/Common/AccessNFTCollectionDetails')
);
const AccessNFTDetails = lazy(() =>
  import('./Components/Common/AccessNFTDetails')
);
const ForFansPage = lazy(() =>
  import('./Components/ForCreatorsAndFans/ForFans')
);
const TermsAndConditions = lazy(() =>
  import('./Components/TermsAndConditions/TermsAndConditions')
);
const PrivacyPolicy = lazy(() =>
  import('./Components/PrivacyPolicy/PrivacyPolicy')
);
const CreatorAgreement = lazy(() =>
  import('./Components/AgreementContentPages/CreatorAgreement')
);
const CreatorLicenseAgreement = lazy(() =>
  import('./Components/AgreementContentPages/CreatorLicenseAgreement')
);
const OrderHistory = lazy(() =>
  import('./Components/Dashboard/OrderHistory/index')
);
const ShippingAddress = lazy(() =>
  import('./Components/Dashboard/ShippingAddress/index')
);
const CongratsBucks = lazy(() =>
  import('./Components/Dashboard/ProceedToCheckout/CongratsBucks')
);
const BucksCompleteTransaction = lazy(() =>
  import('./Components/BucksCompleteTransaction/index')
);
const ConvertBucksSuccess = lazy(() =>
  import('./Components/BucksCompleteTransaction/ConvertBucksSuccess')
);
const StakeWatchlist = lazy(() =>
  import('./Components/Dashboard/StakeWatchlist/index')
);
const CreatorHubInvitation = lazy(() =>
  import('./Components/CreatorHubInvitation')
);
const StandardTraining = lazy(() =>
  import('./Components/CreatorHubMain/GettingStarted/StandardTraining')
);
const AgentInviteForm = lazy(() =>
  import('./Components/ConfirmAgentInvitation')
);

const openRoutes = [
  '/AboutUs',
  '/AboutBucks',
  '/HowItWorks',
  '/ContactUs',
  '/PrivacyPolicy',
  '/TermsAndCondition',
  '/CreatorAgreement',
  '/CreatorLicenseAgreement',
  '/FaqGlossary',
  '/StarStakeGuidelines',
  '/ForFans',
  '/ForCreators',
  '/MintingGuidelines',
  '/StarProfile',
  '/MarketHub',
  '/MissionNFT',
  '/BrandClubDetails',
  '/PromotionDetails',
  '/AccessNFTCollectionDetails',
  '/AccessNFTDetails',
  '/BuckDetails',
  '/CollectionDetails',
  '/BundleDetails',
  '/StarConnectDetails',
  '/ComingSoon',
  '/verify-email',
  '/Agreement',
  '/Invite',
  '/Uploads',
  '/Welcome',
  '/'
];

const TOAST_LIMIT = 3;
function ScrollToTop(props) {
  const { account, isRegistered, hasCreatorAccess, isConnecting } = props;
  const { pathname } = useLocation();
  const history = useHistory();
  const accountRef = useRef(account);
  const isRegisteredRef = useRef(isRegistered);
  const creatorRef = useRef(hasCreatorAccess);
  const { toasts } = useToasterStore();

  useEffect(() => {
    if (isConnecting) return;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    accountRef.current = account;
    isRegisteredRef.current = isRegistered;
    creatorRef.current = hasCreatorAccess;
    const tempPath = pathname.split('/');
    const newPathname = '/'.concat(tempPath[1] ? tempPath[1] : tempPath[0]);
    if (
      creatorRef.current ||
      hasCreatorAccess ||
      props?.agent_manager_profile?.starId
    )
      return;
    if (!openRoutes.includes(newPathname)) {
      let msg = MSG.CONNECT_WALLET_FIRST;
      let redirect = '/';
      if (isRegisteredRef.current || isRegistered) {
        if (newPathname !== '/CreatorHub') return;
        msg = MSG.CLICK_FOR_CREATOR_ACCESS;
        redirect = '/ForCreators';
      } else if (accountRef.current || account) {
        msg = MSG.REGISTER;
        redirect = '/';
        props.connectWallet();
      } else {
        msg = MSG.CONNECT_WALLET_FIRST;
        redirect = '/';
        props.connectWallet();
      }
      errorMsg(msg);
      history.push(redirect);
    }
  }, [pathname, account, hasCreatorAccess, isRegistered, isConnecting]);

  useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((_, i) => i >= TOAST_LIMIT)
      .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  return null;
}

class App extends React.Component {
  constructor() {
    super();

    let hash = window.location.href.split('?ridPage=');

    this.state = {
      fetchingCartDetails: true,
      connectWalletModal: false,
      transactionVerificationModal: false,
      hasCreatorAccess: null,
      confirmAgentReferral: null,
      isFirstWeb3Loading: true,
      connectedWith: null,
      web3: null,
      web3Inst: null,
      chainId: null,
      sandboxAccess: false,
      account: '',
      localAccount: '',
      isEnabled: false,
      isConnecting: hash && hash[1] ? true : false,
      autoConnecting: true,
      canShow: true,
      redirect: null,
      userType: '',
      showManagerTab: null,
      email: '',
      profilePicture: null,
      nickname: '',
      firstName: '',
      lastName: '',
      country: '',
      zipCode: '',
      updatingSignature: false,
      updateSignature: this.updateSignature,
      isRegistered: hash && hash[1] ? true : false,
      setProfile: this.setProfile,
      connect: this.connect,
      changeLoginStatus: this.changeLoginStatus,
      onMagicConnect: this.onMagicOnrampConnect,
      lastLogin: null,
      userHash: '',
      profile: {
        profileImage: null,
        nickname: null,
        email: null
      },
      agent_manager_profile: {
        profileImage: null,
        nickname: null,
        email: null,
        availableCredit: 0,
        activeStars: 0,
        earning: 0,
        starId: null,
        starName: null,
        starIcon: null
      },
      balance: {
        totalBalanceUsd: 0,
        ethBalance: 0,
        usdcBalance: 0,
        minterBalance: 0,
        nrgyBalance: 0,
        nrgyGoBalance: 0,
        ethBalanceUsd: 0,
        usdcBalanceUsd: 0,
        minterBalanceUsd: 0,
        nrgyBalanceUsd: 0,
        nrgyGoBalanceUsd: 0
      },
      cart: {
        _id: null,
        buckInfo: [],
        buckTotalPrice: 0,
        bundleInfo: [],
        bundleTotalPrice: 0
      },
      setWalletSlide: this.setWalletSlide,
      setCartSlide: this.setCartSlide,
      addToCart: this.addToCart,
      sentTransaction: this.sentTransaction,
      isWalletSlideOpen: true,
      isCartSlideOpen: true,
      count: null,
      apiUtility: APIUtiltity,
      cpayPurchaseOrderId: '',
      multiMintStatus: 'start',
      commonStatus: 'start',
      nftReferralAddress: null
    };

    try {
      if (hash && hash[1]) {
        this.setState({ isConnecting: true, connectWalletModal: false });
        const appDataDecrpted = utils.getDecryptedCrossSite(hash[1]);
        if (
          appDataDecrpted?.date &&
          appDataDecrpted?.walletId &&
          appDataDecrpted?.token
        ) {
          let date = new Date();
          let diff = date.getTime() - new Date(appDataDecrpted.date).getTime();
          // alert("Diff::"+diff+"==="+JSON.stringify(appDataDecrpted))
          if (diff / 1000 <= 3) this.crossLogin(appDataDecrpted?.token);
          else {
            errorMsg('Unable to verify the redirection.');
            this.setState({ isConnecting: false, isRegistered: false });
          }
        } else {
          this.setState({ isConnecting: false, isRegistered: false });
        }
      }
    } catch (e) {
      this.setState({ isConnecting: false, isRegistered: false });
    }
  }

  async crossLogin(token = '') {
    try {
      this.setState({ isConnecting: true, connectWalletModal: false });
      const response = await this.state.apiUtility.crossLogin(token);
      if (response.status === 200 && response.data?.token) {
        const obj = {
          email: '',
          accessKey: response.data.token,
          timestamp: moment()
        };
        localStorage.setItem('session_data', utils.getEncrypted(obj));
        this.setState({ isConnecting: false, connectWalletModal: false });
        this.autoConnect();
      }
    } catch (error) {
      console.log(error);
      this.setState({ isConnecting: false, connectWalletModal: false });
      history.replace('/');
    }
  }

  //This is the method to set the context data.
  setProfile = (profile) => {
    this.setState({ profile });
  };
  setWalletSlide = (isOpen) => {
    this.setState({ isWalletSlideOpen: isOpen });
  };
  setCartSlide = (isOpen) => {
    this.setState({ isCartSlideOpen: isOpen });
  };

  async changeUserType(ut) {
    await this.setState({ userType: ut });
  }

  componentDidMount() {
    AOS.init();
    this.autoConnect();
    this.getMasterData();
  }

  async getMasterData() {
    try {
      const response = await this.state.apiUtility.getMasterData();
      if (response.data?.length)
        localStorage.setItem('MasterData', JSON.stringify(response.data));
    } catch (error) {
      console.log(error);
    }
  }

  async autoConnect() {
    try {
      const encryptedData = localStorage.getItem('session_data');
      if (encryptedData) {
        const { timestamp = '' } = utils.getDecrypted(encryptedData);
        const hours = timestamp ? moment().diff(timestamp, 'hours') : 25;
        if (hours < 24) this.getUserData();
        else {
          setTimeout(() => {
            this.setState({ autoConnecting: false });
          }, 1200);
        }
      } else this.setState({ autoConnecting: false });
    } catch (e) {
      console.log(e);
      this.setState({ autoConnecting: false });
    }
  }

  switchNetwork(type) {
    if (type === 'mainnet') {
      this.switchNetworkToMainnet();
    } else if (type === 'polygon') {
      this.switchNetworkToPolygon();
    }
  }

  async switchNetworkToMainnet() {
    let web3 = null;
    if (this.state.connectedWith == 'MetaMask') {
      if (window.ethereum && window.ethereum.isMetaMask) {
        web3 = new Web3(window.ethereum);
      }
    } else if (this.state.connectedWith == 'Fortmatic') {
      let fm = new Fortmatic(FORTMATIC_PRIVATE_KEY, ETH_FORTMATIC_NODE);
      web3 = new Web3(fm.getProvider());
    } else if (this.state.connectedWith == 'MagicLink') {
      const magic = new Magic(MAGICLINK_PRIVATE_KEY, ETH_MAGICLINK_NODE);
      web3 = new Web3(magic.rpcProvider);
      this.checkWalletType(magic);
    }
    if (web3) {
      try {
        await web3.currentProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x' + (1).toString(16) }]
        });
      } catch (error) {
        if (error.code === 4902) {
          try {
            await web3.currentProvider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x' + (1).toString(16),
                  chainName: 'Ethereum Mainnet',
                  rpcUrls: [
                    'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
                  ],
                  nativeCurrency: {
                    name: 'ETH',
                    symbol: 'ETH',
                    decimals: 18
                  },
                  blockExplorerUrls: ['https://etherscan.io']
                }
              ]
            });
          } catch (error) {
            errorMsg(error?.message);
          }
        }
      }
    }
  }

  async switchNetworkToPolygon() {
    let web3 = null;
    if (this.state.connectedWith == 'MetaMask') {
      if (window.ethereum && window.ethereum.isMetaMask) {
        web3 = new Web3(window.ethereum);
      }
    } else if (this.state.connectedWith == 'Fortmatic') {
      let fm = new Fortmatic(FORTMATIC_PRIVATE_KEY, POLY_FORTMATIC_NODE);
      web3 = new Web3(fm.getProvider());
    } else if (this.state.connectedWith == 'MagicLink') {
      const magic = new Magic(MAGICLINK_PRIVATE_KEY, POLY_MAGICLINK_NODE);
      web3 = new Web3(magic.rpcProvider);
      this.checkWalletType(magic);
    }
    if (web3) {
      try {
        await web3.currentProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x' + (137).toString(16) }]
        });
      } catch (error) {
        if (error.code === 4902) {
          try {
            await web3.currentProvider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x' + (137).toString(16),
                  chainName: 'Polygon Mainnet',
                  rpcUrls: [
                    'https://rpc.ankr.com/polygon/b25d252d6cbeb6541d50c6814a8fd69dd8a0aed1d2de505c106dd071a323b876'
                  ],
                  nativeCurrency: {
                    name: 'MATIC',
                    symbol: 'MATIC',
                    decimals: 18
                  },
                  blockExplorerUrls: ['https://polygonscan.com/']
                }
              ]
            });
          } catch (error) {
            errorMsg(error?.message);
          }
        }
      }
    }
  }

  connect = async (type, manual = false) => {
    if (this.state.isConnecting) return;
    if (this.state.localAccount || (!manual && this.state.isRegistered)) return;
    if (!this.state.connectWalletModal && !type) {
      this.setState({ connectWalletModal: true });
      return;
    }
    if (!type) {
      setTimeout(() => {
        $('#connect-wallet-header').trigger('click');
      }, 2000);
      return;
    }
    if (type === 'metamask' && !window.ethereum) {
      if (isMobileDevice) {
        return window.open(
          'https://metamask.app.link/dapp/' + window.location.host
        );
      }
      return toast.loading(
        ({ id }) => (
          <span
            className="cursor-pointer"
            onClick={() => {
              setTimeout(() => toast.dismiss(id), 1000);
              const onboarding = new MetaMaskOnboarding();
              onboarding.startOnboarding();
            }}
          >
            Click here to install MetaMask extension
          </span>
        ),
        { style: { backgroundColor: '#2F96B4' }, icon: infoIcon }
      );
    }
    this.setState({ isConnecting: true, connectWalletModal: false });
    const toastId = toast.loading('Connecting to wallet', {
      style: { backgroundColor: '#2F96B4' },
      icon: infoIcon
    });
    let isConnected = false;
    if (type == 'metamask') {
      isConnected = await this.connectToMetaMask(toastId);
    } else if (type == 'fortmatic') {
      isConnected = await this.connectToFortmatic(toastId);
    } else if (type == 'magiclink') {
      isConnected = await this.connectToMagicLink(toastId);
    }
    if (!isConnected) {
      await this.setState({
        isEnabled: false,
        isConnecting: false,
        connectedWith: null,
        web3: null,
        connectWalletModal: true
        // redirect: '/ConnectWallet'
      });
      toast.dismiss(toastId);
      errorMsg('Wallet connection failed or aborted !');
      await this.setState({ redirect: null });
      this.changesReflactor();
    }
  };

  async connectToMetaMask(toastId) {
    if (window.ethereum && window.ethereum.isMetaMask) {
      this.state.web3 = new Web3(window.ethereum);
      window.ethereum.on('chainChanged', () => {
        this.setConfig(false, false, true);
      });
      window.ethereum.on('accountsChanged', (accounts = []) => {
        if (accounts.length) this.setConfig(false, true, false);
        else window.location.reload();
      });
      window.ethereum.on('connect', () => this.setConfig(false, true, true));
      window.ethereum.on('disconnect', () => {}); // this.disconnect()
      await window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .catch((e) => this.setState({ isEnabled: false, isConnecting: false }));
      await this.setState({ connectedWith: 'MetaMask' });
      localStorage.setItem('connectedWith', 'metamask');
      try {
        await this.setConfig(true);
      } catch (e) {
        await this.setState({ isEnabled: false, isConnecting: false });
      }
      toast.dismiss(toastId);
      return true;
    } else {
      return false;
    }
  }

  async connectToFortmatic(toastId) {
    try {
      let fm = new Fortmatic(FORTMATIC_PRIVATE_KEY, 'goerli');
      // fm.getProvider().isFortmatic // => true
      this.state.web3 = new Web3(fm.getProvider());
      // web3.currentProvider.enable();
      await fm.user.login();
      let isUserLoggedIn = await fm.user.isLoggedIn();
      toast.dismiss(toastId);
      if (isUserLoggedIn) {
        await this.setState({ connectedWith: 'Fortmatic' });
        localStorage.setItem('connectedWith', 'fortmatic');
        await this.setConfig(true);
      }
      return isUserLoggedIn;
    } catch (e) {
      return false;
    }
  }

  async connectToMagicLink(toastId) {
    try {
      const magic = new Magic(MAGICLINK_PRIVATE_KEY, ETH_MAGICLINK_NODE);
      this.state.web3 = new Web3(magic.rpcProvider);
      await this.state.web3.currentProvider.enable();
      toast.dismiss(toastId);
      await this.setState({ connectedWith: 'MagicLink' });
      localStorage.setItem('connectedWith', 'magiclink');
      await this.setConfig(true);
      this.checkWalletType(magic);
      return true;
    } catch (e) {
      return false;
    }
  }

  onMagicOnrampConnect = async () => {
    try {
      const magic = new Magic(MAGICLINK_PRIVATE_KEY, POLY_MAGICLINK_NODE);
      const web3 = new Web3(magic.rpcProvider);
      await web3.currentProvider.enable();
      const accounts = await magic.wallet.connectWithUI();
      this.setState({ web3, magic, magicAccount: accounts[0] });
      return { web3, magic, magicAccount: accounts[0] };
    } catch (e) {
      errorMsg(e?.message);
    }
  };

  async checkWalletType(magic) {
    try {
      const walletInfo = await magic.connect.getWalletInfo();
      localStorage.setItem('walletType', walletInfo.walletType);
    } catch (e) {
      console.log(e);
    }
  }

  async disconnect() {
    try {
      this.setState({ isConnecting: true });
      if (this.state.connectedWith == 'MetaMask') {
        if (window.ethereum && window.ethereum.isMetaMask) {
          window.ethereum.removeListener('chainChanged', () => {});
          window.ethereum.removeListener('accountsChanged', () => {});
          window.ethereum.removeListener('disconnect', () => {});
        }
      } else if (this.state.connectedWith == 'Fortmatic') {
        let fm = new Fortmatic(FORTMATIC_PRIVATE_KEY);
        await fm.user.logout();
      } else if (this.state.connectedWith == 'MagicLink') {
        const magic = new Magic(MAGICLINK_PRIVATE_KEY, ETH_MAGICLINK_NODE);
        await magic.connect.disconnect();
      }
      localStorage.removeItem('connectedWith');
      localStorage.removeItem('session_id');
      localStorage.removeItem('walletType');
      localStorage.removeItem('session_data');
      await this.resetState();
      window.location.reload();
    } catch (e) {
      window.location.reload();
    }
  }

  async resetState() {
    // console.log("RESET STATE::::::")
    await this.setState({
      isEnabled: false,
      connectWalletModal: false,
      hasCreatorAccess: null,
      confirmAgentReferral: null,
      sandboxAccess: false,
      account: '',
      localAccount: '',
      isFirstWeb3Loading: true,
      canShow: true,
      redirect: null,
      userType: '',
      showManagerTab: null,
      email: '',
      profilePicture: null,
      nickname: '',
      firstName: '',
      lastName: '',
      country: '',
      zipCode: '',
      updatingSignature: false,
      isRegistered: false,
      lastLogin: null,
      userHash: '',
      profile: {
        profileImage: null,
        nickname: null,
        email: null,
        totalBalanceUsd: 0,
        ethBalance: 0,
        usdcBalance: 0,
        minterBalance: 0,
        nrgyBalance: 0,
        nrgyGoBalance: 0,
        ethBalanceUsd: 0,
        usdcBalanceUsd: 0,
        minterBalanceUsd: 0,
        nrgyBalanceUsd: 0,
        nrgyGoBalanceUsd: 0
      },
      agent_manager_profile: {
        profileImage: null,
        nickname: null,
        email: null,
        availableCredit: 0,
        activeStars: 0,
        earning: 0,
        starId: null,
        starName: null
      },
      balance: {
        totalBalanceUsd: 0,
        ethBalance: 0,
        usdcBalance: 0,
        minterBalance: 0,
        nrgyBalance: 0,
        nrgyGoBalance: 0,
        ethBalanceUsd: 0,
        usdcBalanceUsd: 0,
        minterBalanceUsd: 0,
        nrgyBalanceUsd: 0,
        nrgyGoBalanceUsd: 0
      },
      cart: {
        _id: null,
        buckInfo: [],
        buckTotalPrice: 0,
        bundleInfo: [],
        bundleTotalPrice: 0
      },
      totalBucksValue: 0,
      totalBucksCount: 0,
      totalSnftCount: 0,
      totalSnftAmount: 0,
      totalAccessNftCount: 0,
      commonStatus: 'start'
    });
  }

  updateStarData(newStarId = null, newStarName = null, newStarIcon = null) {
    this.setState({
      agent_manager_profile: {
        starId: newStarId,
        starName: newStarName,
        starIcon: newStarIcon
      }
    });
  }

  async setConfig(isNew, isAccountChange, isChainChange) {
    try {
      if (isAccountChange) {
        this.setState({ isConnecting: true });
        this.resetState();
      }
      // const networkId = await window.ethereum.request({
      //   method: 'net_version'
      // });
      const networkId = await this.state.web3.eth.net.getId();
      // console.log("networkId::", networkId)
      let accounts = [];
      if (this.state.connectedWith == 'MetaMask') {
        try {
          accounts = await window.ethereum.request({
            method: 'eth_requestAccounts'
          });
        } catch (error) {
          console.log(error);
          return infoMsg('MetaMask locked! Please unlock to connect wallet');
        }
      } else accounts = await this.state.web3.eth.getAccounts();
      if (!accounts || !accounts.length) {
        errorMsg(MSG['SOMETHING_WRONG']);
        return this.setState({ isConnecting: false });
      }
      const walletId = accounts[0]?.toLowerCase?.();
      await this.configNetwork(networkId, walletId);
    } catch (e) {
      console.log(e);
    }
  }

  async configNetwork(chainId, account) {
    chainId = Number(chainId);
    if (CHAINID.indexOf(chainId) === -1) {
      infoMsg(MSG['WRONG_NETWORK']);
      await this.setState({ isEnabled: false, isConnecting: false });
      this.changesReflactor();
      throw new Error();
    } else if (CHAINID.indexOf(chainId) >= 0) {
      if (this.state.isFirstWeb3Loading) {
        const web3Inst = new this.state.web3.eth.Contract(
          TREASURY_ABI,
          LIST_ADDRESS_BUCKS
        );
        await this.setState({
          isFirstWeb3Loading: false,
          isEnabled: true,
          chainId: chainId,
          web3Inst: web3Inst,
          localAccount: account,
          isConnecting: false
        });
      } else {
        await this.setState({
          isFirstWeb3Loading: false,
          isEnabled: true,
          chainId: chainId,
          localAccount: account,
          isConnecting: false
        });
      }
      this.changesReflactor();
    } else {
      this.changesReflactor();
    }
  }

  async authorizeUser(walletId = '') {
    if (localStorage.getItem('sessionData')) {
      const sessionData = JSON.parse(localStorage.getItem('sessionData'));
      if (sessionData[walletId]) {
        const hours = moment().diff(sessionData[walletId].timestamp, 'hours');
        if (hours < 24) {
          localStorage.setItem('session_id', sessionData[walletId].session_id);
          return true;
        } else localStorage.removeItem('session_id');
      } else localStorage.removeItem('session_id');
    }
    return await this.updateSignature(walletId);
  }

  async getBalances(account = this.state.account) {
    try {
      const data = await this.state.apiUtility.getUserBalances(account);
      // console.log("BALANCE::::: res DATA", data);
      if (data.status === 200) {
        await this.setState({
          balance: {
            ethBalance: data.data.ethBalance,
            usdcBalanceEth: data.data.usdcBalanceEth,
            usdcBalancePoly: data.data.usdcBalancePoly,
            minterBalance: data.data.minterBalance,
            maticBalance: data.data.maticBalance,
            totalUsd: data.data.totalUsd
          }
        });
      } else if (data.status === 401) this.updateSignature();
    } catch (error) {
      // console.log(error);
    }
  }

  async getUserBucksBalance(account) {
    try {
      const data = await this.state.apiUtility.getMyAssets();
      // console.log("Bucks Count res DATA", data);
      if (data.status === 200) {
        await this.setState({
          totalBucksValue:
            data.data.buckTotalAmount + data.data.adbuckTotalAmount,
          totalBucksCount: data.data.myBucks + data.data.myAdBuck,
          totalSnftCount: data.data.mySnft,
          totalSnftAmount: data.data.totalStakingAmount,
          totalAccessNftCount: data.data.myAccessNft,
          assets: data.data
        });
      } else if (data.status === 401) this.updateSignature();
    } catch (error) {
      console.log(error);
    }
  }

  async checkUserCreator(walletId) {
    try {
      if (walletId) {
        const data = await this.state.apiUtility.hasCreatorPageAccess(walletId);
        if (data.status == 200) {
          this.setState({
            hasCreatorAccess: data.data.creatorHubAccess,
            confirmAgentReferral: data.data.confirmAgentReferral
          });
        } else if (data.status === 401) this.updateSignature();
      }
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  async getUserDetails(walletId) {
    try {
      const data = await this.state.apiUtility.getProfileDetails(walletId);
      if (data.status === 200) {
        await this.setState({
          agent_manager_profile: {
            profileImage: data.data.userProfile.profilePicture,
            nickname: data.data.userProfile.nickName,
            email: data.data.userProfile.email,
            availableCredit: data.data.agentProfile?.availableCredit,
            activeStars: data.data.agentProfile?.activeStars
              ? data.data.agentProfile.activeStars
              : 0,
            earning: data.data.agentProfile?.earning
              ? data.data.agentProfile.earning
              : 0
          },
          nickname: data.data.userProfile.nickName,
          firstName: data.data.userProfile.firstName,
          lastName: data.data.userProfile.lastName,
          country: data.data.userProfile?.country,
          zipCode: data.data.userProfile?.zipCode,
          profilePicture: data.data.userProfile.profilePicture
        });
      } else if (data.status === 401) this.updateSignature();
      return true;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async checkUserExist(account) {
    try {
      const res = await this.state.apiUtility.getUserStatus(account);
      if (res.status === 404) {
        await this.setState({ isRegistered: false, isConnecting: false });
        return null;
      } else if (res.status === 403) {
        this.disconnect();
        return false;
      } else if (res.status === 200) {
        if (!res.data || !res.data.nickName) return false;
        await this.setState({
          account: account,
          email: res.data.email,
          nickname: res.data.nickName,
          profilePicture: res.data.profilePicture,
          isRegistered: res.data.isRegistered,
          lastLogin: res.data.lastLogin,
          userType: res.data.userType,
          showManagerTab: res.data.showManagerTab,
          userHash: res.data.userHash
        });
        const authorized = await this.authorizeUser(account);
        if (!authorized) {
          errorMsg(MSG.CANCELLED_SIGN_IN + ' ' + MSG.PLEASE_SIGN_IN);
          return false;
        }
        await this.checkUserCreator(account, res.data.userType);
        await this.getUserDetails(account);
        return true;
      } else {
        errorMsg(MSG['SOMETHING_WRONG'] + ' ' + MSG['RECONNECT_WALLET']);
        return null;
      }
    } catch (e) {
      console.error('ERROR::::' + e);
      return null;
    }
  }

  async getUserData() {
    this.setState({ isConnecting: true });
    const res = await this.state.apiUtility.getUserData();
    if (res?.status === 200) {
      const walletId = res.data?.walletId;
      if (walletId) {
        this.setState({
          sandboxAccess: true,
          account: walletId,
          email: res.data.email,
          nickname: res.data.nickName,
          firstName: res.data?.firstName,
          lastName: res.data?.lastName,
          profilePicture: res.data.profilePicture,
          lastLogin: res.data.lastLogin,
          userType: res.data.userType,
          showManagerTab: res.data.showManagerTab,
          userHash: res.data.userHash,
          isRegistered: true,
          displayName: res.data.displayName
        });
        await this.checkUserCreator(walletId);
        await this.getBalances(walletId);
        await this.getUserBucksBalance(walletId);
        await this.getCart();
        await this.getUserDetails(walletId);
        this.setReferralWalletAddress(walletId);
      } else this.setState({ connectWalletModal: true });
    } else {
      let data = utils.getDecrypted(localStorage.getItem('session_data'));
      delete data.timestamp;
      localStorage.setItem('session_data', utils.getEncrypted(data));
      setTimeout(() => this.setState({ connectWalletModal: true }), 1000);
    }
    this.setState({ autoConnecting: false, isConnecting: false });
  }

  async updateSignature(account = '') {
    if (this.state.updatingSignature || this.state.isRegistered) return false;
    try {
      const walletId = account || this.state.account;
      if (!walletId) {
        errorMsg(MSG['CONNECT_WALLET_FIRST']);
        return false;
      }
      this.setState({ updatingSignature: true });

      const nonceRes = await this.state.apiUtility.generateNonceToken(walletId);
      if (nonceRes.status !== 200) return false;
      const nonce = nonceRes.data?.nonce;
      if (!nonce) {
        errorMsg(nonceRes.message || MSG.SOMETHING_WRONG);
        return false;
      }

      const nonceFinal = `Hi Welcome to StarStake!\n\nClick "Sign" to sign in. No password needed!\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will be reset after 24 hours.\n\nI accept the StarStake Terms of Service\n${
        '\nNonce : ' + nonce + '\nWallet address: ' + walletId
      }`;
      const signature = await this.state.web3.eth.personal.sign(
        nonceFinal,
        walletId,
        (err, signature) => {
          if (err || !signature) {
            this.setState({ updatingSignature: false });
            if ([4001, 32603].includes(err?.code || 0)) return false;
          }
        }
      );
      const userSecretObj = { signature: signature };
      const res = await this.state.apiUtility.validateSignInSignature(
        walletId,
        userSecretObj
      );
      this.setState({ updatingSignature: false });
      if (res.status === 200) {
        if (res.data?.session_id) {
          let sessionData = localStorage.getItem('sessionData');
          if (sessionData) sessionData = JSON.parse(sessionData);
          else sessionData = {};
          sessionData[walletId] = { ...res.data, timestamp: moment() };
          localStorage.setItem('sessionData', JSON.stringify(sessionData));
          localStorage.setItem('session_id', res.data.session_id);
          if (!this.state.isRegistered) {
            this.setState({ isRegistered: true, isConnecting: true });
          }
          return true;
        } else {
          errorMsg(res.message || MSG.SOMETHING_WRONG);
          return false;
        }
      } else return false;
    } catch (e) {
      console.log('ERROR:::::::', JSON.stringify(e));
      this.setState({ updatingSignature: false, isFirstWeb3Loading: true });
      return false;
    }
  }

  async changeLoginStatus({ email, nickname, isRegistered, userType }) {
    await this.setConfig(false, false, false);
    await this.setState({
      email: email,
      nickname: nickname,
      isRegistered: isRegistered,
      userType: userType
    });
    this.changesReflactor();
  }

  async changesReflactor() {
    await this.setState({ canShow: false });
    await this.setState({ canShow: true });
  }

  async getCart() {
    if (!this.state.account) {
      errorMsg(MSG['CONNECT_WALLET_FIRST']);
      return;
    }
    this.setState({ fetchingCartDetails: true });
    try {
      const data = await this.state.apiUtility.getCartByWalletId(
        this.state.account
      );
      if (data.status === 401) {
        this.updateSignature();
        this.setState({ fetchingCartDetails: false });
      } else if (data.status === 200) {
        if (data.data) await this.setState({ cart: data.data });
        this.setState({ fetchingCartDetails: false });
        this.changesReflactor();
      }
    } catch (e) {
      console.error('ERROR::::' + e);
      this.setState({ fetchingCartDetails: false });
    }
  }

  addToCart = async (item) => {
    if (!this.state.account || !this.state.isRegistered) {
      errorMsg(MSG[this.state.account ? 'REGISTER' : 'CONNECT_WALLET_FIRST']);
      this.connect();
      return;
    }
    if (item.type === 'buck' && this.state.cart.bundleInfo?.length) {
      return errorMsg('Please purchase bundle added to cart first!');
    } else if (item.type === 'bundle' && this.state.cart.buckInfo?.length) {
      return errorMsg('Please purchase bucks added to cart first!');
    }
    try {
      let cartObj = {
        cartId: this.state.cart._id,
        walletId: this.state.account,
        type: item.type,
        quantity: item.quantity || 1,
        itemId: item._id
      };
      const data = await this.state.apiUtility.addCart(cartObj);
      if (data.status === 404) {
        errorMsg(MSG['UNABLE_ADD_CART']);
        return false;
      } else if (data.status === 401) {
        this.updateSignature();
      } else if (data.status === 200) {
        this.setState({ cart: data.data });
        this.setState({ count: data.data });
        successMsg(MSG['ADDED_TO_CART']);
        return true;
      } else {
        errorMsg(data?.message || MSG['UNABLE_ADD_CART']);
        return false;
      }
    } catch (e) {
      console.error('ERROR::::' + e);
      errorMsg(e?.message || MSG['UNABLE_ADD_CART']);
    }
  };

  getTotalBalance() {
    let balance = 0;
    if (this.state.balance.ethBalance) {
      balance = Number(this.state.balance.ethBalance);
    }
    if (this.state.totalBucksValue) {
      balance = balance + Number(this.state.totalBucksValue);
    }
    return balance;
  }

  isAnyTransactionInProgress() {
    if (
      this.state.multiMintStatus == 'inprogress' ||
      this.state.commonStatus == 'inprogress'
    ) {
      return true;
    }
    return false;
  }

  sentTransaction = async (cpayPurchaseOrderId = '') => {
    if (this.state.isEnabled) {
      try {
        if (POLYGONID.indexOf(this.state.chainId) === -1) {
          this.switchNetwork('polygon');
          return;
        }
        if (!this.state.account) {
          errorMsg(MSG['CONNECT_WALLET_FIRST']);
          return;
        }

        if (this.isAnyTransactionInProgress()) {
          warningMsg(MSG['TRANSACTION_IN_PROGRESS']);
          return;
        }

        let dataObj = {
          quantityList: [],
          buckTokenContractAddress: []
        };

        let totalUsdValue = 0;
        if (
          this.state.cart &&
          this.state.cart.buckInfo &&
          this.state.cart.buckInfo.length > 0
        ) {
          // console.log("this.state.cart.buckInfo", this.state.cart.buckInfo)
          for (let obj of this.state.cart.buckInfo) {
            let index = dataObj.buckTokenContractAddress.indexOf(
              obj.contractAddress
            );
            if (index >= 0) {
              dataObj.quantityList[index] =
                Number(obj.quantity) + dataObj.quantityList[index];
            } else if (index == -1) {
              dataObj.quantityList.push(Number(obj.quantity));
              dataObj.buckTokenContractAddress.push(obj.contractAddress);
            }
            totalUsdValue =
              totalUsdValue + Number(obj.quantity) * Number(obj.marketPrice);
          }
        }

        if (
          this.state.cart &&
          this.state.cart.bundleInfo &&
          this.state.cart.bundleInfo.length > 0
        ) {
          // await this.setBundle(dataObj);
          for (let bundleItem of this.state.cart.bundleInfo) {
            // console.log(JSON.stringify(bundleItem));
            for (let obj of bundleItem['bucksArray']) {
              let index = dataObj.buckTokenContractAddress.indexOf(
                obj.contractAddress
              );
              if (index >= 0) {
                dataObj.quantityList[index] =
                  Number(obj.quantity) + dataObj.quantityList[index];
              } else if (index == -1) {
                dataObj.quantityList.push(Number(obj.quantity));
                dataObj.buckTokenContractAddress.push(obj.contractAddress);
              }
            }
            totalUsdValue = totalUsdValue + Number(bundleItem.itemTotalPrice);
          }
        }
        if (dataObj.buckTokenContractAddress.length > 0) {
          //ADD Validation for USDC balance
          if (
            !this.state.balance ||
            !(this.state.balance && this.state.balance.usdcBalancePoly) ||
            !(this.state.balance && this.state.balance.usdcBalancePoly > 0)
          ) {
            errorMsg(MSG['INSUFFICIENT_BALANCE']);
            return;
          }

          // for (let i = 0; i < dataObj.buckTokenContractAddress.length; i++) {}

          this.setState({ multiMintStatus: 'inprogress' });
          let buyAmount = this.state.web3.utils.toWei(
            totalUsdValue + '',
            'mwei'
          );
          const { bal: usdcbal = 0 } = await ContractUtils.balanceOf(
            this.state.web3,
            ERC20_ABI,
            LIST_ADDRESS_USDC,
            this.state.account
          );

          if (Number(usdcbal) < Number(buyAmount)) {
            errorMsg(
              MSG['AVAIL_BALANCE_ACCORDING'].replace(
                '{balance}',
                Number(buyAmount) - Number(usdcbal)
              )
            );
            this.setState({ multiMintStatus: 'start' });
            return;
          }
          const res1 = await ContractUtils.hasAllownce(
            this.state.web3,
            ERC20_ABI,
            LIST_ADDRESS_USDC,
            this.state.account,
            LIST_ADDRESS_TREASURY,
            buyAmount
          );
          if (res1.msg === 'success') {
            if (!res1.result) {
              const approvRes = await ContractUtils.approveLogic(
                this.state.web3,
                ERC20_ABI,
                LIST_ADDRESS_USDC,
                this.state.account,
                LIST_ADDRESS_TREASURY
              );
              if (approvRes.result !== true) {
                // console.log("ERROR1")
                errorMsg(approvRes.msg);
                this.setState({ multiMintStatus: 'start' });
                return;
              }
            }
          } else {
            errorMsg(res1.msg);
            this.setState({ multiMintStatus: 'start' });
            return;
          }

          let web3Object = new this.state.web3.eth.Contract(
            TREASURY_ABI,
            LIST_ADDRESS_TREASURY
          );
          try {
            const { result, msg } = await ContractUtils.sendTxWithoutValue(
              web3Object,
              this.state.web3,
              [
                dataObj.buckTokenContractAddress,
                dataObj.quantityList,
                buyAmount,
                this.state.account
              ],
              'purchaseMultipleBucks',
              this.state.account
            );
            if (result) {
              successMsg(MSG['NFTBUCKS_PURCHASED_SUCCESS']);
              // this.getCart();
              const obj = utils.getEncrypted({
                ...this.state.cart,
                totalUsdValue: totalUsdValue
              });
              await this.setState({ redirect: 'CongratsBucks/' + obj });
              await this.setState({
                cart: {
                  _id: this.state.cart._id,
                  buckInfo: [],
                  buckTotalPrice: 0,
                  bundleInfo: [],
                  bundleTotalPrice: 0
                }
              });
              await this.state.apiUtility.addOrder({
                cartId: this.state.cart._id
              });
              this.getBalances(this.state.account);
              this.getUserBucksBalance(this.state.account);
              await this.setState({ redirect: null });
              this.setState({ multiMintStatus: 'start' });
              return true;
            } else {
              this.setState({ multiMintStatus: 'start' });
              errorMsg(msg);
            }
          } catch (e) {
            errorMsg(MSG['SOMETHING_WRONG']);
            this.setState({ multiMintStatus: 'start' });
          }
        }
      } catch (e) {
        console.error(e);
        errorMsg(MSG['SOMETHING_WRONG']);
        this.setState({ multiMintStatus: 'start' });
      }
    } else if (this.state.account) {
      if (cpayPurchaseOrderId) {
        this.confirmTransactionHandler('', cpayPurchaseOrderId);
      } else this.setState({ transactionVerificationModal: true });
    } else {
      errorMsg(MSG['CONNECT_WALLET_FIRST']);
      this.setState({ multiMintStatus: 'start' });
    }
  };

  async setBundle(dataObj) {
    try {
      for (let obj of this.state.cart.bundleInfo) {
        const data = await this.state.apiUtility.getBuckFromBundle(obj._id);
        if (data.status == 200) {
          dataObj.contracts = dataObj.contracts.concat(
            data.data.buckContractAddress
          );
          dataObj.quantityList = dataObj.quantityList.concat(
            data.data.buckQuantity
          );
        } else if (data.status == 401) {
          this.updateSignature();
        }
      }
    } catch (e) {
      console.error(e);
      await this.setBundle(dataObj);
    }
  }

  async setReferralWalletAddress(walletId = '') {
    const referralWalletId = utils.getDataFromCookie('referralWalletId');
    if (!walletId || !referralWalletId) return;
    if (!utils.compareWalletAddresses(referralWalletId, walletId)) {
      const res = await this.state.apiUtility.setReferralWalletAddress(
        referralWalletId
      );
      if (res.status === 200) utils.storeDataIntoCookie('referralWalletId', '');
      else console.log(res);
    }
  }

  async callbackCalls(event) {
    if (event?.fnName) {
      await this[event.fnName](event.args);
    }
  }

  setNFTReferral(event) {
    console.log('Setting Referral::::::::::::::');
    this.setState({ nftReferralAddress: event });
  }

  async setRedirection(pathName) {
    await this.setState({ redirect: pathName });
    await this.setState({ redirect: null });
  }

  setCartDataFromCheckout(cartObj = {}) {
    this.setState({ cart: cartObj });
  }

  async confirmTransactionHandler(txnPwd = '', cpayPurchaseOrderId = '') {
    this.setState({ multiMintStatus: 'inprogress' });
    let res;
    if (cpayPurchaseOrderId) {
      res = await this.state.apiUtility.confirmPurchaseUsingCreditCard({
        orderId: cpayPurchaseOrderId
      });
    } else {
      res = await this.state.apiUtility.addOrder({
        cartId: this.state.cart._id,
        txnPwd: utils.getEncryptedPassword(txnPwd)
      });
    }
    if (res.status === 200) {
      const obj = utils.getEncrypted({
        ...this.state.cart,
        ...res.data,
        purchaseDateTime: new Date()
      });
      await this.setState({ redirect: 'CongratsBucks/' + obj });
      await this.setState({
        cart: {
          _id: this.state.cart._id,
          buckInfo: [],
          buckTotalPrice: 0,
          bundleInfo: [],
          bundleTotalPrice: 0
        },
        transactionVerificationModal: false
      });
      this.getBalances(this.state.account);
      this.getUserBucksBalance(this.state.account);
      this.setState({ redirect: null });
    } else if (res.status == 401) this.updateSignature();
    else if (res.status === 400 && !cpayPurchaseOrderId) {
      errorMsg(res.message);
      this.setState({ transactionVerificationModal: true });
    } else errorMsg(res.message);
    this.setState({ multiMintStatus: 'start' });
  }

  render() {
    return (
      <MainContext.Provider value={this.state}>
        <IntercomProvider appId={appId} autoBoot={false}>
          <ErrorBoundary>
            <HashRouter history={history} forceRefresh={true}>
              <Toaster
                toastOptions={{
                  style: {
                    backgroundColor: '#111C30',
                    fontSize: '13px',
                    fontWeight: '500',
                    padding: '12px 20px',
                    color: '#ffffff'
                  }
                }}
                containerStyle={{ marginTop: '100px', marginRight: '20px' }}
                position="top-right"
                reverseOrder={false}
              />
              <ScrollToTop
                agent_manager_profile={this.state.agent_manager_profile}
                isRegistered={this.state.isRegistered}
                account={this.state.account}
                hasCreatorAccess={this.state.hasCreatorAccess}
                connectWallet={(type) => {
                  this.connect(type);
                }}
                isConnecting={
                  this.state.isConnecting || this.state.autoConnecting
                }
              />
              {this.state.sandboxAccess ? (
                <Suspense
                  fallback={
                    <div className="page-loader">
                      <LoaderIcon />
                    </div>
                  }
                >
                  <NewHeader
                    history={history}
                    prop={this.state}
                    connect={(type) => this.connect(type)}
                    disconnect={() => this.disconnect()}
                    callback={(event) => this.callbackCalls(event)}
                    updateUserState={(wallet) => this.checkUserCreator(wallet)}
                    updateStarData={(newId, newName, newIcon) =>
                      this.updateStarData(newId, newName, newIcon)
                    }
                  />
                  <Suspense
                    fallback={
                      <div className="page-loader">
                        <LoaderIcon />
                      </div>
                    }
                  >
                    <Switch>
                      <Route
                        exact
                        path={`/`}
                        render={(props) => <LandingPage />}
                      />
                      <Route
                        exact
                        path={`/AboutUs`}
                        render={(props) => <AboutUsNew />}
                      />
                      <Route
                        exact
                        path={`/MintingGuidelines`}
                        render={() => <MintGuidelines />}
                      />
                      <Route
                        exact
                        path={`/FaqGlossary`}
                        render={() => <FaqGlossary />}
                      />
                      <Route
                        exact
                        path="/ForFans"
                        render={() => <ForFansPage />}
                      />
                      <Route
                        exact
                        path={`/ComingSoon`}
                        render={() => <ComingSoon />}
                      />
                      <Route
                        exact
                        path={`/PrivacyPolicy`}
                        render={() => <PrivacyPolicy />}
                      />
                      <Route
                        exact
                        path={`/TermsAndCondition`}
                        render={() => <TermsAndConditions />}
                      />
                      <Route
                        exact
                        path={`/StarStakeGuidelines/:type?`}
                        render={() => <StarStakeGuidelines />}
                      />
                      <Route
                        exact
                        path={`/CreatorAgreement`}
                        render={() => <CreatorAgreement />}
                      />
                      <Route
                        exact
                        path={`/CreatorLicenseAgreement`}
                        render={() => <CreatorLicenseAgreement />}
                      />
                      {/* <Route
                      exact
                      path={`/AboutBucks`}
                      render={(props) => <AboutBucks />}
                    />
                    <Route
                      exact
                      path={`/HowItWorks`}
                      render={(props) => <HowItWorks />}
                    /> */}
                      <Route
                        exact
                        path={`/ContactUs`}
                        render={(props) => (
                          <ContactUs
                            prop={this.state}
                            history={history}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            sentTransaction={(amount) => {
                              this.sentTransaction(amount);
                            }}
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/verify-email`}
                        render={(props) => (
                          <VerifyEmail
                            prop={this.state}
                            history={history}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/ShippingAddress`}
                        render={(props) => (
                          <ShippingAddress
                            prop={this.state}
                            history={history}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            disconnect={() => {
                              this.disconnect();
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            sentTransaction={(amount) => {
                              this.sentTransaction(amount);
                            }}
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                          />
                        )}
                      />
                      <Route
                        exact
                        path={'/BuckDetails/:buckId'}
                        render={(props) => (
                          <BuckDetails
                            prop={this.state}
                            buckId={props.match.params.buckId}
                            history={history}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            sentTransaction={(amount) => {
                              this.sentTransaction(amount);
                            }}
                            addToCart={(event) => {
                              this.addToCart(event);
                            }}
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                          />
                        )}
                      />
                      <Route
                        exact
                        path={'/CollectionDetails/:collectionId'}
                        render={(props) => (
                          <CollectionDetails
                            prop={this.state}
                            history={history}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            sentTransaction={(amount) => {
                              this.sentTransaction(amount);
                            }}
                            addToCart={(event) => {
                              this.addToCart(event);
                            }}
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/BundleDetails/:id`}
                        render={(props) => (
                          <BundleDetails
                            prop={this.state}
                            history={history}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            addToCart={(event) => {
                              this.addToCart(event);
                            }}
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/CongratsBucks/:data`}
                        render={() => <CongratsBucks prop={this.state} />}
                      />
                      <Route
                        exact
                        path={`/ProceedToCheckout/:orderId?`}
                        render={(props) => (
                          <ProceedToCheckout
                            prop={this.state}
                            orderId={props.match.params.orderId}
                            history={history}
                            connect={(type) => this.connect(type)}
                            disconnect={() => this.disconnect()}
                            callback={(event) => this.callbackCalls(event)}
                            setCartDataFromCheckout={(cartObj) =>
                              this.setCartDataFromCheckout(cartObj)
                            }
                            sentTransaction={(amount) =>
                              this.sentTransaction(amount)
                            }
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                            getUserBalance={(walletId) =>
                              this.getBalances(walletId)
                            }
                          />
                        )}
                      />
                      <Route
                        exact
                        path="/CreditCardPayment/:orderId"
                        render={() => (
                          <CreditCardPayment
                            walletId={this.state.account}
                            sentTransaction={(amount) => {
                              this.sentTransaction(amount);
                            }}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/StakeWatchlist`}
                        render={(props) => (
                          <StakeWatchlist
                            prop={this.state}
                            history={history}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            sentTransaction={(amount) => {
                              this.sentTransaction(amount);
                            }}
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/OrderHistory`}
                        render={(props) => (
                          <OrderHistory
                            prop={this.state}
                            history={history}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            disconnect={() => {
                              this.disconnect();
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            sentTransaction={(amount) => {
                              this.sentTransaction(amount);
                            }}
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/StarProfile/:id?`}
                        render={(props) => (
                          <StarProfileDetails
                            prop={this.state}
                            history={history}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            sentTransaction={(amount) => {
                              this.sentTransaction(amount);
                            }}
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                            addToCart={(event) => {
                              this.addToCart(event);
                            }}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/StarConnectDetails/:id?`}
                        render={() => (
                          <StarConnectDetails
                            prop={this.state}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/AccessNFTCheckout/:token?`}
                        render={(props) => (
                          <AccessNFTCheckout
                            prop={this.state}
                            history={history}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            sentTransaction={(amount) => {
                              this.sentTransaction(amount);
                            }}
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/BrandClubCheckout/:token?`}
                        render={(props) => (
                          <SnftCheckout
                            prop={this.state}
                            history={history}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            sentTransaction={(amount) => {
                              this.sentTransaction(amount);
                            }}
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/BrandClubDetails/:id?`}
                        render={(props) => (
                          <SnftDetailsPage
                            prop={this.state}
                            history={history}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            sentTransaction={(amount) => {
                              this.sentTransaction(amount);
                            }}
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/PromotionDetails/:id?`}
                        render={(props) => (
                          <PromotionDetailsPage
                            prop={this.state}
                            history={history}
                            connect={(type) => this.connect(type)}
                            callback={(event) => this.callbackCalls(event)}
                            sentTransaction={(amount) =>
                              this.sentTransaction(amount)
                            }
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                          />
                        )}
                      />
                      <Route
                        path="/BrandClubHome/:id?"
                        render={(props) => (
                          <BrandClubHome
                            {...props}
                            prop={this.state}
                            callback={(event) => this.callbackCalls(event)}
                          />
                        )}
                      />

                      {/* STAKEHUB */}
                      <Route
                        path={`/StakeHub`}
                        render={(props) => (
                          <StakeHub
                            prop={this.state}
                            history={history}
                            addToCart={(event) => {
                              this.addToCart(event);
                            }}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            disconnect={() => {
                              this.disconnect();
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            sentTransaction={(amount) => {
                              this.sentTransaction(amount);
                            }}
                            updateUserState={(wallet) =>
                              this.getUserDetails(wallet)
                            }
                            updateStarData={(newId, newName, newIcon) =>
                              this.updateStarData(newId, newName, newIcon)
                            }
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/AccessNFTDetails/:id?`}
                        render={(props) => (
                          <AccessNFTDetails
                            prop={this.state}
                            history={history}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            sentTransaction={(amount) => {
                              this.sentTransaction(amount);
                            }}
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/AccessNFTCollectionDetails/:id?`}
                        render={(props) => (
                          <AccessNFTCollectionDetails
                            prop={this.state}
                            history={history}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            sentTransaction={(amount) => {
                              this.sentTransaction(amount);
                            }}
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/ConvertBucks`}
                        render={(props) => (
                          <BucksCompleteTransaction
                            prop={this.state}
                            history={history}
                            connect={(type) => this.connect(type)}
                            callback={(event) => this.callbackCalls(event)}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/ConvertBucksSuccess`}
                        render={(props) => (
                          <ConvertBucksSuccess
                            prop={this.state}
                            history={history}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                          />
                        )}
                      />

                      {/* CREATOR HUB */}
                      <Route
                        path={`/CreatorHub`}
                        render={(props) => (
                          <CreatorHub
                            prop={this.state}
                            history={history}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            disconnect={() => {
                              this.disconnect();
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            updateUserState={(wallet) =>
                              this.getUserDetails(wallet)
                            }
                            updateStarData={(newId, newName, newIcon) =>
                              this.updateStarData(newId, newName, newIcon)
                            }
                          />
                        )}
                      />
                      <Route
                        path={`/ForCreators`}
                        render={(props) => (
                          <CreatorHubInvitation
                            prop={this.state}
                            history={history}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/TrainingCenter/:courseId`}
                        render={(props) => (
                          <StandardTraining
                            prop={this.state}
                            history={history}
                            connect={(type) => {
                              this.connect(type);
                            }}
                            disconnect={() => {
                              this.disconnect();
                            }}
                            callback={(event) => {
                              this.callbackCalls(event);
                            }}
                            updateUserState={(wallet) =>
                              this.checkUserExist(wallet)
                            }
                          />
                        )}
                      />
                      <Route
                        path="/MarketHub"
                        render={(props) => (
                          <MarketHubWrapper
                            prop={this.state}
                            connect={() => this.connect()}
                            addToCart={(event) => this.addToCart(event)}
                            callback={(event) => this.callbackCalls(event)}
                            {...props}
                          />
                        )}
                      />
                      <Route
                        path="/MissionNFT"
                        render={(props) => (
                          <MissionNFTWrapper
                            prop={this.state}
                            connect={(type) => this.connect(type, true)}
                            addToCart={(event) => this.addToCart(event)}
                            callback={(event) => this.callbackCalls(event)}
                            setNFTReferral={(event) =>
                              this.setNFTReferral(event)
                            }
                            {...props}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={`/ConfirmAgentInvitation`}
                        render={() => (
                          <AgentInviteForm
                            prop={this.state}
                            updateUserState={(wallet, action) => {
                              this.checkUserCreator(wallet);
                              if (action === 'approved')
                                this.setState({ userType: 'star' });
                            }}
                          />
                        )}
                      />
                      <Route path="*" render={() => <Error404 />} />
                    </Switch>
                  </Suspense>
                  {this.state.isConnecting || this.state.autoConnecting ? (
                    <div className="main home">
                      <div className="page-loader">
                        <LoaderIcon />
                      </div>
                    </div>
                  ) : null}
                  {this.state.redirect ? (
                    <Redirect to={this.state.redirect} />
                  ) : null}
                </Suspense>
              ) : (
                <SandboxPage
                  onEnterSandbox={() =>
                    this.setState({ connectWalletModal: true })
                  }
                />
              )}
              <ConnectModal
                show={this.state.connectWalletModal}
                onHide={(connected = false) => {
                  this.setState({ connectWalletModal: false });
                  if (connected) this.getUserData();
                }}
              />
              <TransactionVerificationModal
                show={this.state.transactionVerificationModal}
                onHide={(txnPwd = '') => {
                  if (txnPwd) this.confirmTransactionHandler(txnPwd);
                  this.setState({ transactionVerificationModal: false });
                }}
              />
              {this.isAnyTransactionInProgress() ? (
                <LoaderModal></LoaderModal>
              ) : null}
            </HashRouter>
          </ErrorBoundary>
        </IntercomProvider>
      </MainContext.Provider>
    );
  }
}

export default App;
